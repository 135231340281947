/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from "vue";
import router from "./router";
import "@mdi/font/css/materialdesignicons.css";
import Vuetify from "vuetify";
import VueRouter from "vue-router";
import "vuetify/dist/vuetify.min.css";
import Flutterwave from "vue-flutterwave";
import FlagIcon from "vue-flag-icon";

// Install BootstrapVue
import moment from "moment";
import "es6-promise/auto";
import VueApexCharts from "vue-apexcharts";
import VueChatScroll from "vue-chat-scroll";
import VueSignaturePad from "vue-signature-pad";
import { store } from "./store";
import App from "./App.vue";
import "./registerServiceWorker";
import { abbreviateNumber } from "./utils";
import i18n from "./i18n";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css";
import VueTour from "vue-tour";
import VueIntercom from "vue-intercom";
import { init } from "commandbar";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import VueAwesomeSwiper from "vue-awesome-swiper";

// import style
import "swiper/css/swiper.css";

Vue.use(VueAwesomeSwiper /* { default options with global component } */);

let prodURL = "https://soc-net.builtaccounting.com/";
Vue.use(
  new VueSocketIO({
    connection: SocketIO(prodURL),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },

  })

);
Vue.use(VueChatScroll);

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

require("./bootstrap");

require("vue-tour/dist/vue-tour.css");

Vue.use(VueSignaturePad);

Vue.use(VueTour);
Vue.use(Flutterwave, { publicKey: store.state.flutterwave_key });

Vue.use(VueIntercom, { appId: "ev5nda20" });

Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(FlagIcon);

Vue.use(Vuesax);

init("02d6f771");


Vue.prototype.moment = moment;
Vue.config.productionTip = false;
Vuetify.config.silent = true;

new Vue({
  router,
  store,
  moment,
  i18n,
  vuetify: new Vuetify(),
  render: (h) => h(App),

  mounted() {
    let URL = new URLSearchParams(window.location.search);
    let token = URL.get("r");
    if (token) {
      localStorage.setItem("media_uuid", token);
    }
  },
}).$mount("#app");

Vue.filter("humanDate", (d) => {
  if (d) {
    return moment(d, "YYYY-MM-DD").format("MMM Do, YYYY");
  }
});

Vue.filter("humanDateTime", (d) => {
  if (d) {
    return moment(d).format("MMM Do YYYY, h:mm A");
  }
});

Vue.filter("absolute", (d) => {
  if (d) {
    if (!isNaN(d)) {
      return d < 0
        ? "(" +
            (Number(d) / -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
            ")"
        : Number(d)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return d;
    }
  } else {
    return 0;
  }
});

Vue.filter("currency_symbol", (d) => {
  if (d < 0) {
    return (
      "-" +
      store.state.user.user_infor.current_business.currency.symbol +
      d / -1
    );
  } else {
    return store.state.user.user_infor.current_business.currency.symbol + d;
  }
});

const symbol = (d) => {
  const currencySymbol =
    store.state.user.user_infor.current_business.currency.symbol;
  if (!isNaN(d)) {
    if (d < 0) return "-" + currencySymbol + d / -1;
    return currencySymbol + d;
  } else {
    return currencySymbol + d;
  }
};

Vue.filter("currency_symbol", (d) => symbol(d));

Vue.filter("invoiceCurrencySymbol", (d) => {
  const invoiceSymbol = store.state.invoiceCurrencySymbol;
  if (invoiceSymbol === "") return symbol(d);

  if (d < 0) return "-" + invoiceSymbol + d / -1;
  return invoiceSymbol + d;
});

Vue.filter("toMoney", (d) => {
  if (d) {
    return Number(d)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return "0.00";
  }
});

Vue.filter("fromNow",(d)=>{

  return moment(d).fromNow();

});

Vue.filter("salesMoney", (d) => {
  if (d) {
    const decimals = d.toString().substring(4, d.toString().length);

    let fixedDec = 0;

    if (Number(decimals) > 0) {
      fixedDec = d.toFixed(4);
    } else {
      fixedDec = d.toFixed(2);
    }

    return fixedDec.replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return "0.00";
  }
});

Vue.filter("short_number", (d) => {
  return abbreviateNumber(d);
});

Vue.filter("removeUnderscore", (d) => {
  return d.split("_").join(" ");
});
