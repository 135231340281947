<template>
  <div>
    <div v-if="CAN_VIEW" class="container">
      <center>
        <v-progress-circular
          color="blue"
          size="200"
          indeterminate
          v-if="progress"
        ></v-progress-circular>
      </center>

      <v-row dense v-if="!progress">
        <v-col cols="12" sm="9" class="mx-auto">
          <v-card flat class="mb-7" style="transitoin:0.3s ease-in-out">
            <v-card-text>
              <v-toolbar rounded shaped flat v-if="!$store.state.selected_invoice.deleted_at">
                <v-toolbar-items style="overflow: auto !important">
                  <v-btn small color="blue" text to="/income/invoices">
                    <v-icon>mdi-arrow-left</v-icon>
                    {{ $t("main.back") }}
                  </v-btn>
                  <v-btn
                      small
                      text
                      color="blue"
                      :to="'/income/invoices/edit/' + $store.state.selected_invoice.id"
                      :disabled="check_status"
                      v-if="CAN_EDIT"
                  >{{ $t("main.edit") }}
                  </v-btn>

                  <v-btn
                      v-if="CAN_ADD_PAYMENT"
                      small
                      text
                      color="blue"
                      @click="add_payment($store.state.selected_invoice)"
                  >{{ $t("invoice.add_payment") }}
                  </v-btn>
                  <v-btn
                      small
                      color="blue"
                      rounded
                      depressed
                      text
                      @click="send_reminder_dialog2 = true"
                      v-if="CAN_ADD_PAYMENT"
                  >{{ $t("main.send_reminder") }}
                  </v-btn>

                  <v-btn
                      small
                      text
                      color="blue"
                      @click="$store.state.show_share_sheet = true"
                      v-if="CAN_DOWNLOAD"
                      id="sharBtnID"
                  >{{ $t("main.share") }}
                  </v-btn>
                  <v-btn
                      class="text-capitalize"
                      small
                      text
                      color="blue"
                      @click="print_invoice($store.state.selected_invoice)"
                  >{{ $t("main.print") }}
                  </v-btn>
                  <v-btn
                      small
                      text
                      color="blue"
                      v-if="CAN_DOWNLOAD"
                      :href="
                    $store.state.baseURL +
                    '/' +
                    'downloadPDF/' +
                    $store.state.selected_invoice.uuid
                  "
                  >{{ $t("main.download_pdf") }}
                  </v-btn>

                  <v-tooltip bottom>

                    <template v-slot:activator="{on}">
                      <v-btn v-on="on" to="/invoices/new" x-large color="blue darken-4" dark depressed class="rounded-xl"><v-icon>mdi-plus</v-icon> Invoice</v-btn>

                    </template>
                    <span>Create a new invoice</span>
                  </v-tooltip>

                  <v-menu bottom left transition="scale-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn color="blue" icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                          @click="add_withholding($store.state.selected_invoice)"
                          v-if="CAN_ADD_PAYMENT"
                      >
                        <v-list-item-title>{{
                            $t("invoice.add_withholding")
                          }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                          :href="
                        $store.state.baseURL +
                        '/' +
                        'invoicereciept/' +
                        $store.state.selected_invoice.enc_id
                      "
                          target="_blank"
                          v-show="
                        $store.state.selected_invoice.payment_history.length >
                          0 ||
                        $store.state.selected_invoice.online_payments.length > 0
                      "
                      >
                        <v-list-item-title>{{
                            $t("invoice.view_payment_receipt")
                          }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                          v-show="
                        $store.state.selected_invoice.payment_history.length >
                          0 ||
                        $store.state.selected_invoice.online_payments.length > 0
                      "
                          @click="sendReceiptSMSDialog = true"
                      >
                        <v-list-item-title>{{
                            $t("main.send_receipt_via_sms")
                          }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                          v-show="
                        $store.state.selected_invoice.withholding.length > 0
                      "
                          @click="$vuetify.goTo('#withholding_taxes')"
                          v-if="CAN_ADD_PAYMENT"
                      >
                        <v-list-item-title>{{
                            $t("invoice.remove_withholding")
                          }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                          v-show="
                        $store.state.selected_invoice.payment_history.length ||
                        $store.state.selected_invoice.online_payments.length
                      "
                          v-if="CAN_ADD_PAYMENT"
                          @click="$vuetify.goTo('#payments')"
                      >
                        <v-list-item-title>{{
                            $t("main.payment_history")
                          }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          v-if="
                        $store.state.selected_invoice.reminder_plan.length ===
                          0 && $store.state.selected_invoice.amount_paid === 0
                      "
                          @click="add_reminder_dialog = true"
                      >
                        <v-list-item-title>{{
                            $t("invoice.add_reminder")
                          }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="discountInvoice = true">
                        <v-list-item-title>{{
                            $t("invoice.discount_invoice")
                          }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                          @click="delete_invoice($store.state.selected_invoice)"
                          :disabled="check_status"
                          v-if="CAN_DELETE"
                      >
                        <v-list-item-title class="red--text">{{
                            $t("main.delete")
                          }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar-items>
              </v-toolbar>
              <item-deleted-component v-else></item-deleted-component>
            </v-card-text>
          </v-card>
        </v-col>


      </v-row>
      <v-card
        flat
        v-if="!progress"
        style="border-radius: 15px !important; transitoin:0.3s ease-in-out"
        class="col-md-9 mx-auto"
        :class="$vuetify.theme.isDark ? 'bg-black' : 'bg-white'"
      >

        <anchor-component
          :tags="$store.state.selected_invoice.tag"
        ></anchor-component>

        <div :style="colorScheme.toHeader" class="stretch-out">
          <div class="d-flex p-3 text-right" :class="posClasses">
            <div :class="centerClass">
              <v-img
                :src="$store.state.user.user_infor.current_business.logo"
                :width="
                  sizes[$store.state.selected_invoice.logo_size]
                    ? sizes[$store.state.selected_invoice.logo_size]
                    : $store.state.selected_invoice.logo_size
                "
              ></v-img>
            </div>

            <div :class="textAlign" style="width: 40%">
              <div class="mb-0 d-block" style="font-size: 2rem">
                {{
                  $store.state.user.user_infor.current_business.invoice_settings
                    .title
                }}
              </div>

              <small
                v-if="
                  $store.state.user.user_infor.current_business.invoice_settings
                    .subhaeder
                "
                style="white-space: pre-line"
                class="text-left"
              >
                {{
                  $store.state.user.user_infor.current_business.invoice_settings
                    .subhaeder
                }}
              </small>

              <h4 v-if="invoiceSettings.tax_id" class="mb-0 font-weight-light">
                <strong>{{ $t("invoice.tax_id") }}:</strong>
                <span>{{
                  invoiceSettings.tax_id ? invoiceSettings.tax_id : ""
                }}</span>
              </h4>

              <h4 class="mb-0 mt-0 font-weight-light">
                <strong>No:</strong>
                <span>
                  #{{ $store.state.selected_invoice.invoice_number }}</span
                >
              </h4>

              <h4 class="mb-0 mt-0 font-weight-light">
                <strong>{{ $t("main.issue_date") }}: </strong>
                <span>{{
                  $store.state.selected_invoice.start_date | humanDate
                }}</span>
              </h4>

              <h4 class="mb-0 mt-0 font-weight-light">
                <strong>{{ $t("main.due_date") }}: </strong>
                <span>{{
                  $store.state.selected_invoice.end_date | humanDate
                }}</span>
              </h4>

              <h4 class="mt-0 font-weight-light">
                <strong>{{ $t("invoice.payment_status") }}: </strong>
                <span
                  v-if="$store.state.selected_invoice.paid_status == 'Paid'"
                  class="text-success p-1 text-center"
                  >{{ $store.state.selected_invoice.paid_status }}
                </span>
                <span
                  v-if="$store.state.selected_invoice.paid_status == 'Not Paid'"
                  class="text-danger p-1 text-center"
                  >{{ $store.state.selected_invoice.paid_status }}
                </span>
                <span
                  v-if="
                    $store.state.selected_invoice.paid_status == 'Paid Part'
                  "
                  class="text-warning p-1 text-center"
                  >{{ $store.state.selected_invoice.paid_status }} </span
                ><br />
                <span
                  v-if="
                    $store.state.selected_invoice.payment_history.length > 0
                  "
                  class="p-1 text-center"
                >
                  <strong>{{ $t("invoice.recent_payment_date") }}:</strong>
                  {{
                    $store.state.selected_invoice.payment_history[0].date_paid
                      | humanDate
                  }}
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div
          class="d-flex justify-space-between flex-column flex-md-row flex-lg-row mt-0"
        >
          <div>
            <p class="font-weight-black mb-0">{{ $t("invoice.billed_to") }}:</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  x-small
                  :to="
                    '/income/customers/' + $store.state.selected_invoice.customer.id
                  "
                  text
                  :color="$vuetify.theme.isDark ? 'white':'blue'"
                  class="p-0"
                  >{{ $store.state.selected_invoice.customer.business_name }}
                </v-btn>
              </template>
              <span>{{ $t("main.view") }} {{ $t("main.customer") }}</span>
            </v-tooltip>

            <span
              class="d-block minor-text"
              v-if="$store.state.selected_invoice.customer.business_address"
              >{{
                $store.state.selected_invoice.customer.business_address
              }}</span
            >
            <span
              class="d-block minor-text"
              v-if="$store.state.selected_invoice.customer.business_phone"
              >{{ $store.state.selected_invoice.customer.business_phone }}</span
            >
            <span
              class="d-block minor-text"
              v-if="$store.state.selected_invoice.customer.business_email"
              >{{ $store.state.selected_invoice.customer.business_email }}</span
            >
          </div>
          <div></div>
          <div class="text-right">
            <p class="font-weight-black mb-0">From:</p>

            <p class="text-muted mb-0">
              {{ $store.state.user.user_infor.current_business.name }}
            </p>

            <span
              v-if="$store.state.user.user_infor.current_business.address"
              class="d-block minor-text"
            >
              {{ $store.state.user.user_infor.current_business.address }}</span
            >
            <span
              v-if="$store.state.user.user_infor.current_business.phone_number"
              class="d-block minor-text"
            >
              {{
                $store.state.user.user_infor.current_business.phone_number
              }}</span
            >
            <span
              v-if="$store.state.user.user_infor.current_business.email"
              class="d-block minor-text"
            >
              {{ $store.state.user.user_infor.current_business.email }}</span
            >
          </div>
        </div>

        <v-row>
          <v-col cols="12" sm="7" class="ml-auto">
            <headers-view
              :headers="$store.state.selected_invoice.extra_headers"
            ></headers-view>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" class="p-0">
            <v-simple-table>
              <thead :style="colorScheme.header">
                <tr class="text-white">
                  <th class="text-center text-white">S/N</th>
                  <th
                    class="text-left text-white"
                    v-if="invoiceSettings.show_item"
                  >
                    {{ invoiceSettings.item }}
                  </th>
                  <th
                    class="text-right text-white"
                    v-if="invoiceSettings.show_price"
                  >
                    {{ invoiceSettings.price }}
                  </th>
                  <th
                    class="text-center text-white"
                    v-if="invoiceSettings.show_quantity"
                  >
                    {{ invoiceSettings.quantity }}
                  </th>
                  <th
                    class="text-right text-white"
                    v-if="invoiceSettings.show_amount"
                  >
                    {{ invoiceSettings.amount }}
                  </th>
                  <th class="text-right text-white" v-if="invoiceSettings.show_discount">{{invoiceSettings.discount}}</th>
                  <th
                    class="text-right text-white"
                    v-if="invoiceSettings.show_tax_amount"
                  >
                    {{ invoiceSettings.tax_amount }}
                  </th>
                  <th class="text-right text-white" v-if="invoiceSettings.show_amount_due">{{invoiceSettings.amount_due}}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in $store.state.selected_invoice.items"
                  :key="item.id"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td v-if="invoiceSettings.show_item">
                    <v-btn
                      :to="'/inventory/' + item.product_id"
                      target="_blank"
                      x-small
                      text
                      :color="$vuetify.theme.isDark ? 'white':'blue'"
                      class="p-0"
                    >
                      {{ item.details.name }}
                      <v-icon x-small>mdi-open-in-new</v-icon>
                    </v-btn>
                    <br />
                    <small class="text-muted">{{ item.description }}</small>
                  </td>

                  <td v-if="invoiceSettings.show_price" class="text-right">
                    {{ fx.symbol }}{{ item.unit_price | salesMoney }}
                  </td>
                  <td v-if="invoiceSettings.show_quantity" class="text-center">
                    {{ item.invoice_quantity }}
                  </td>
                  <td v-if="invoiceSettings.show_amount" class="text-right">
                    {{ fx.symbol }}{{ item.amount | toMoney }}
                  </td>
                  <td class="text-right" v-if="invoiceSettings.show_discount">
                    {{ fx.symbol }}{{ item.discount_amount | toMoney
                    }}<br v-if="item.discount_percent > 0" />
                    <small v-if="item.discount_percent > 0"
                      >{{ Number(item.discount_percent).toFixed(2) }}%</small
                    >
                  </td>

                  <td v-if="invoiceSettings.show_tax_amount" class="text-right">
                    {{ fx.symbol }}{{ item.formatedTax_amount }}
                  </td>
                  <td class="text-right" v-if="invoiceSettings.show_amount_due">
                    {{ fx.symbol }}{{ item.amount_due | toMoney }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <span
              v-if="
                $store.state.user.user_infor.current_business.invoice_settings
                  .show_payment && $store.state.user.user_infor.current_business.bank_name &&  $store.state.user.user_infor.current_business.account_name && $store.state.user.user_infor.current_business.account_number
              "
            >
              <h4 class="mb-1">
                <strong>{{ $t("invoice.payment_info") }}:</strong>
              </h4>
              <strong>{{ $t("invoice.payment_method") }}:</strong>
              {{ $store.state.user.user_infor.current_business.bank_name
              }}<br />
              <strong>{{ $t("invoice.account_name") }}:</strong>
              {{ $store.state.user.user_infor.current_business.account_name
              }}<br />
              <strong>{{ $t("invoice.account_number") }}:</strong>
              {{ $store.state.user.user_infor.current_business.account_number }}
            </span>
          </v-col>

          <v-col cols="12" sm="6" class="text-right">
            <h4 class="mb-2">
              <strong> {{ $t("main.subtotal") }}: </strong>

              <span style="opacity: 0.7">
                {{ fx.symbol }}{{ selectedInvoice.gross_amount | toMoney }}
              </span>
            </h4>

            <h4
              class="mb-2"
              v-if="canShowInvoiceItem(selectedInvoice.discount_amount)"
            >
              <strong> {{ $t("main.discount") }}: </strong>

              <span style="opacity: 0.7">
                ({{ fx.symbol }}{{ selectedInvoice.discount_amount | toMoney }})
              </span>
            </h4>
            <h4
              class="mb-2"
              v-if="canShowInvoiceItem(selectedInvoice.net_amount)"
            >
              <strong> {{ $t("main.net_amount") }}: </strong>
              <span style="opacity: 0.7">
                {{ fx.symbol
                }}{{
                  (selectedInvoice.gross_amount -
                    selectedInvoice.discount_amount)
                    | toMoney
                }}
              </span>
            </h4>
            <span
              class="font-weight-light"
              v-for="(tax, i) in selectedInvoice.taxes"
              :key="i"
            >
              <span v-if="tax.type == 'Compound'">
                <h4 v-for="(subtax, i) in tax.sub_tax" class="mb-1" :key="i">
                  <strong> {{ subtax.name }} ({{ subtax.rate }}%): </strong>

                  <span style="opacity: 0.7">
                    {{ fx.symbol
                    }}{{
                      (tax.total_amount * (Number(subtax.rate) / 100)) | toMoney
                    }}
                  </span>
                </h4>
                <h4 class="mb-1">
                  <strong> {{ tax.name }} ({{ tax.rate }}%): </strong>
                  <span style="opacity: 0.7">
                    {{ fx.symbol }}{{ tax.tax_amount | toMoney }}
                  </span>
                </h4>
              </span>

              <h4 v-else class="mb-1">
                <strong> {{ tax.name }}({{ tax.rate }}%): </strong>
                <span style="opacity: 0.7"
                  >{{ fx.symbol }}{{ tax.tax_amount | toMoney }}</span
                >
              </h4>
            </span>

            <h4
              class="mb-2"
              v-if="canShowInvoiceItem(selectedInvoice.amount_paid)"
            >
              <strong> {{ $t("payment.amount_paid") }}: </strong>

              <span style="opacity: 0.7">
                {{ fx.symbol }}{{ selectedInvoice.amount_paid | toMoney }}
              </span>
            </h4>

            <h4 class="mb-2" v-if="canShowInvoiceItem(selectedInvoice.balance)">
              <strong> {{ $t("main.credit_balance") }}: </strong>
              <span style="opacity: 0.7">
                {{ fx.symbol }}{{ selectedInvoice.balance | toMoney }}
              </span>
            </h4>
            <h4 class="mb-2" v-if="selectedInvoice.withholding.length > 0">
              <strong> {{ $t("main.withdolding_applied") }}: </strong>

              <span style="opacity: 0.7">
                {{ fx.symbol
                }}{{ selectedInvoice.withholding[0].amount | toMoney }}
              </span>
            </h4>
            <h4
              class="mb-2"
              v-if="canShowInvoiceItem(selectedInvoice.amount_due)"
            >
              <strong> {{ $t("main.amount_due") }}: </strong>

              <span style="opacity: 0.7">
                {{ fx.symbol }}{{ selectedInvoice.amount_due | toMoney }}
              </span>
            </h4>

            <h4
              class="mb-2"
              v-if="
                selectedInvoice.fx_currency !== selectedInvoice.base_currency
              "
            >
              <strong> {{ $t("main.amount_base") }}: </strong>
              <span style="opacity: 0.7">
                {{ selectedInvoice.fx_amount | toMoney | currency_symbol
                }}<br />
                <small
                  >{{ $t("invoice.rate") }}:
                  {{ selectedInvoice.fx_rate }}</small
                >
              </span>
            </h4>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            v-if="
              $store.state.user.user_infor.current_business.invoice_settings
                .show_note
            "
          >
            <div class="text-justify">
              <strong v-if="$store.state.selected_invoice.note"
                >{{ $t("main.note") }}:</strong
              >
              <p class="mt-2" style="opacity: 0.7">
                {{ $store.state.selected_invoice.note }}
              </p>
              <journal-link
                type="invoice"
                :invoice_id="$store.state.selected_invoice.id"
              ></journal-link>
            </div>
          </v-col>
        </v-row>

        <p
          class="p-2 bg-light text-muted text-center"
          v-if="
            $store.state.user.user_infor.current_business.invoice_settings
              .footer
          "
          style="white-space: pre-line"
        >
          {{
            $store.state.user.user_infor.current_business.invoice_settings
              .footer
          }}
        </p>

        <hr />

        <visists-component
          v-if="!progress"
          :invoice_uuid="$store.state.selected_invoice.uuid"
        ></visists-component>

        <v-row
          id="payments"
          v-if="
            $store.state.selected_invoice.payment_history.length ||
            $store.state.selected_invoice.online_payments.length
          "
        >
          <v-col cols="12" sm="12">
            <paymentsComponent></paymentsComponent>
          </v-col>
        </v-row>

        <v-row
          id="withholding_taxes"
          v-if="$store.state.selected_invoice.withholding.length > 0"
        >
          <v-col cols="12" sm="12">
            <removewithholdingComponent></removewithholdingComponent>
          </v-col>
        </v-row>

      </v-card>



      <div
        v-if="selectedInvoice && selectedInvoice.has_attachments && !progress"
        class="card col-md-9 mx-auto"
        :class="$vuetify.theme.isDark ? 'bg-black' : 'bg-white'"
      >
        <h2 class="font-weight-light" v-if="attachments.length > 0">
          Manage Attached Files
        </h2>
        <attachment
          :attachments="attachments"
          :showDownload="true"
          :isRemoveLoading="isAttachmentRemoveLoading"
          @fileRemoved="handleAttachmentRemoved"
          :show-dropzone="false"
        >
        </attachment>
      </div>

      <v-dialog width="500" persistent v-model="discountInvoice" scrollable>
        <discounting-component
          @closed="discountInvoice = false"
          :invoice="selectedInvoice"
        ></discounting-component>
      </v-dialog>

      <children-component v-if="!progress"></children-component>
      <reminder-view-component v-if="!progress"></reminder-view-component>

      <!--send email dialig-->
      <v-dialog persistent width="500" scrollable v-model="send_dialog">
        <v-card>
          <v-card-title class="font-weight-light">
            {{ $t("invoice.send_email") }}
            <v-spacer></v-spacer>
            <v-btn
              @click="send_dialog = false"
              color="red lighten-5 red--text"
              rounded
              depressed
              >{{ $t("main.close") }}
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="warning"
            v-if="saving_progress"
          ></v-progress-linear>
          <v-card-text>
            <v-row>
              <v-col>
                <v-chip color="blue lighten-5 blue--text"
                  >{{ $t("invoice.invoice_number") }}: {{ invoice_number }}
                </v-chip>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="5">
                <v-switch
                  v-model="send_tome"
                  :label="$t('main.send_email_copy')"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="7">
                <v-switch
                  v-model="send_attachments"
                  :label="$t('invoice.send_attachment')"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="12">
                <h2 class="font-weight-light">
                  {{ $t("main.send_to") }}: {{ customer_name }}
                </h2>
                {{ customer_email }}<br />
                <small>{{ $t("quote.email_info") }}</small>
              </v-col>
              <v-col cols="12" sm="12">
                <v-form ref="mails_form">
                  <v-list>
                    <v-list-item
                      v-for="(email, index) in other_mails"
                      :key="index"
                    >
                      <v-text-field
                        autofocus
                        @keydown.native.tab="add_email"
                        :rules="emailRules"
                        type="email"
                        :placeholder="$t('main.email')"
                        dense
                        outlined
                        v-model="email.address"
                      ></v-text-field>

                      <v-list-item-action>
                        <v-btn
                          class="mb-4"
                          @click="remove_email(index)"
                          icon
                          color="red"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-btn @click="add_email()" rounded small block text
                        >{{ $t("main.add") }} {{ $t("main.email") }}
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-form>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="email_message"
                  rows="2"
                  outlined
                  label="Message"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="send_email"
              color="blue darken-4"
              dark
              block
              rounded
              large
              >{{ $t("main.send") }} {{ $t("main.invoice") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end send email dialog-->

      <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        @input="get_invoice()"
        width="500"
        :value="$store.state.payment_dialog"
        scrollable
        persistent
      >
        <paymentComponent></paymentComponent>
      </v-dialog>

      <v-dialog width="500" v-model="delete_dialog" :persistent="saving_progress">
        <v-card>
          <v-card-title color="red" class="text-danger">
            {{ $t("main.delete") }}?
          </v-card-title>
          <v-card-text>
            <strong
              >{{ $t("invoice.delete_info") }} {{ invoice_number }}
              {{ $t("invoice.billed_to") }}
              {{ customer_name }}
              {{ $t("invoice.together_transactions") }},
              <span class="text-danger"
                >{{ $t("main.proceed") }} ?</span
              ></strong
            >
          </v-card-text>
          <v-card-actions>
            <v-btn @click="remove_invoice" color="error" x-large rounded depressed block>{{
              $t("main.delete")
            }}</v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        width="500"
        v-model="$store.state.withholding_dialog"
        persistent
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card>
          <v-card-title class="font-weight-light">
            {{ $t("invoice.add_withholding") }}
            <v-spacer></v-spacer>
            <v-btn
              @click="$store.state.withholding_dialog = false"
              color="red lighten-5 red--text"
              depressed
              rounded
            >
              {{ $t("main.close") }}
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <addwithholdingComponent></addwithholdingComponent>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="duplicate_dialog" width="600">
        <v-card>
          <v-card-title
            >{{ $t("invoice.confirm_duplicate") }}
            <span class="m-2 p-1 text-small text-success border border-success"
              >Beta</span
            ></v-card-title
          >
          <v-card-text>
            {{ $t("invoice.duplicate_warning") }}
            {{ $t("invoice.invoice_no") }}
            {{ $store.state.selected_invoice.invoice_number }} ?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="duplicate_dialog = false" text>{{
              $t("main.cancel")
            }}</v-btn>
            <v-btn
              :loading="duplicating"
              @click="duplicate_invoice()"
              text
              color="blue darken-4"
            >
              {{ $t("invoice.duplicate") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent scrollable v-model="add_reminder_dialog" width="500">
        <reminder-create-component
          @close_dialog="add_reminder_dialog = false"
          @get_updated_invoice="get_invoice"
          @success="
            success_message = true;
            success_msg = 'Reminder set successfully!';
          "
        >
        </reminder-create-component>
      </v-dialog>
      <v-dialog
        persistent
        scrollable
        width="500"
        v-model="send_reminder_dialog2"
      >
        <manual-reminder-component
          v-if="!progress"
          @close_dialog="send_reminder_dialog2 = false"
          @success="
            success_message = true;
            success_msg = 'Reminder sent successfully!';
          "
          @error="
            error_message = true;
            error_msg = 'Could not send reminder, try again!';
          "
          :customerEmail="$store.state.selected_invoice.customer.business_email"
          :invoice="$store.state.selected_invoice"
          :customerName="$store.state.selected_invoice.customer.business_name"
        >
        </manual-reminder-component>
      </v-dialog>

      <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="smsDialog"
        width="500px"
        persistent
      >
        <send-s-m-s-c-omponent
          @closed="smsDialog = false"
          :invoice="$store.state.selected_invoice"
        ></send-s-m-s-c-omponent>
      </v-dialog>

      <v-dialog
        :fullscreen="$vuetify.breakpoint.mobile"
        v-model="sendReceiptSMSDialog"
        width="500px"
        persistent
      >
        <send-receipts-s-m-s
          :invoice="$store.state.selected_invoice"
          @closed="sendReceiptSMSDialog = false"
        ></send-receipts-s-m-s>
      </v-dialog>

      <v-snackbar v-model="error_message" color="error">
        {{ error_msg }}
      </v-snackbar>

      <v-snackbar v-model="success_message">
        {{ success_msg }}
      </v-snackbar>
      <share-sheet-component></share-sheet-component>
    </div>
    <v-row v-else>
      <v-col cols="12" sm="6" class="mx-auto">
        <cannotview-component></cannotview-component>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { CURRENCY } from "@/data";
import CannotviewComponent from "../patials/cannotviewComponent";
import AnchorComponent from "../tags/anchorComponent";
import ChildrenComponent from "./childrenComponent";
import ItemDeletedComponent from "../audit/itemDeletedComponent";
import reminderViewComponent from "./reminderViewComponent.vue";
import reminderCreateComponent from "./reminderCreateComponent.vue";
import ManualReminderComponent from "./manualReminderComponent.vue";
import JournalLink from "./journalLink";
import HeadersView from "./headersView";
import SendSMSCOmponent from "./SendSMSCOmponent";
import VisistsComponent from "./VisistsComponent";
import SendReceiptsSMS from "./SendReceiptsSMS";
import DiscountingComponent from "./DiscountingComponent";
import ShareSheetComponent from "./shareSheetComponent";
import TourComponent from "../tour/TourComponent";

const paymentComponent = () => import("../patials/paymentComponent");
const paymentsComponent = () => import("../patials/paymentsComponent");
const addwithholdingComponent = () =>
  import("../patials/addwithholdingComponent");
const removewithholdingComponent = () =>
  import("../patials/removewithholdingComponent");
const Attachment = () => import("../patials/attachment");

export default {
  name: "invoicepreviewComponent",
  components: {
    TourComponent,
    ShareSheetComponent,
    DiscountingComponent,
    SendReceiptsSMS,
    VisistsComponent,
    SendSMSCOmponent,
    HeadersView,
    JournalLink,
    ItemDeletedComponent,
    ChildrenComponent,
    AnchorComponent,
    Attachment,
    CannotviewComponent,
    paymentComponent,
    paymentsComponent,
    addwithholdingComponent,
    removewithholdingComponent,
    reminderViewComponent,
    reminderCreateComponent,
    ManualReminderComponent,
  },

  data() {
    return {
      sizes: {
        medium: "100px",
        small: "50px",
        large: "150px",
      },
      discountInvoice: false,
      sendReceiptSMSDialog: false,
      smsDialog: false,
      add_reminder_dialog: false,
      send_reminder_dialog2: false,
      send_attachments: false,
      duplicating: false,
      duplicate_dialog: false,
      not_alloowed: false,
      paymentHistory_dialog: false,
      delete_dialog: false,
      email_message: "",
      progress: true,
      invoice: null,
      send_dialog: false,
      customer_name: "",
      invoice_number: "",
      customer_email: "",
      invoice_id: "",
      other_mails: [],
      error_msg: "",
      error_message: false,
      success_message: false,
      success_msg: "",
      send_tome: false,
      saving_progress: false,
      fx: { symbol: "" },
      currencyList: CURRENCY,
      color: "#1572E8",
      logoPos: "left",
      logoSize: "medium",
      isAttachmentRemoveLoading: false,
      emailRules: [
        (v) => !!v || this.$t("main.email_required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("main.invalid_email"),
      ],
    };
  },

  computed: {
    attachments() {
      return this.$store.state.selected_invoice.attachments;
    },

    taxes() {
      return this.business.taxes;
    },
    business() {
      return this.$store.state.user.user_infor.current_business;
    },
    itemsDiscount() {
      let sum = 0;
      this.$store.state.selected_invoice.items.forEach((item) => {
        sum += Number(item.discount_amount);
      });

      return sum;
    },
    invoiceDiscount() {
      const invoice_discount = this.$store.state.selected_invoice
        ? this.$store.state.selected_invoice.discount_amount
        : 0;
      return Number(invoice_discount) - Number(this.itemsDiscount);
    },
    invioceID() {
      return this.$route.params.id;
    },
    compact_other_emails() {
      let list = [];

      this.other_mails.forEach((mail) => {
        list.push(mail.address);
      });
      return list;
    },
    invoiceSettings() {
      return this.$store.state.user.user_infor.current_business
        .invoice_settings;
    },

    CAN_ADD() {
      if (this.$store.state.user.user_infor.is_admin === 1) {
        return true;
      } else
        return (
          this.$store.state.user.user_infor.components[0].roles.create === 1
        );
    },
    CAN_EDIT() {
      if (this.$store.state.user.user_infor.is_admin === 1) {
        return true;
      } else
        return this.$store.state.user.user_infor.components[0].roles.edit === 1;
    },
    CAN_DELETE() {
      if (this.$store.state.user.user_infor.is_admin === 1) {
        return true;
      } else
        return (
          this.$store.state.user.user_infor.components[0].roles.delete === 1
        );
    },
    CAN_VIEW() {
      if (this.$store.state.user.user_infor.is_admin === 1) {
        return true;
      } else
        return this.$store.state.user.user_infor.components[0].roles.view === 1;
    },
    CAN_DOWNLOAD() {
      if (this.$store.state.user.user_infor.is_admin === 1) {
        return true;
      } else
        return (
          this.$store.state.user.user_infor.components[0].roles.download === 1
        );
    },
    CAN_SEND() {
      if (this.$store.state.user.user_infor.is_admin === 1) {
        return true;
      } else
        return this.$store.state.user.user_infor.components[0].roles.send === 1;
    },
    CAN_ADD_PAYMENT() {
      if (this.$store.state.user.user_infor.is_admin === 1) {
        return true;
      } else
        return (
          this.$store.state.user.user_infor.components[0].roles.payments === 1
        );
    },
    CAN_CHANGE_TERMS() {
      if (this.$store.state.user.user_infor.is_admin === 1) {
        return true;
      } else
        return (
          this.$store.state.user.user_infor.components[0].roles.terms === 1
        );
    },
    colorScheme() {
      const isTransparent =
        this.color === "transparent" || this.color === "#00000000";
      return {
        header: {
          "background-color": this.$store.state.selected_invoice.invoice_color,
          color: !isTransparent ? "#ffffff" : "black",
        },
        toHeader: {
          "background-color":
            this.selectedInvoice.style === "default"
              ? this.$store.state.selected_invoice.invoice_color
              : (this.$vuetify.theme.isDark ? 'black' : '#ffffff'),
          color: this.selectedInvoice.style === "default" ? "#ffffff" : (this.$vuetify.theme.isDark ? "#ffffff" : "#000000"),
          "border-bottom": this.selectedInvoice.style !== "default" ? "1px solid #eee" : "",
        },
      };
    },

    baseUrl() {
      return this.$store.state.baseURL;
    },
    selectedInvoice() {
      return this.$store.state.selected_invoice;
    },
    check_status() {
      return (
        this.$store.state.selected_invoice.paid_status != "Not Paid" ||
        this.$store.state.selected_invoice.payment_history.length > 0 ||
        this.$store.state.selected_invoice.online_payments.length > 0 ||
        this.$store.state.selected_invoice.withholding.length > 0
      );
    },
    payment_done() {
      return this.$store.state.payment_dialog;
    },
    payment_removed() {
      return this.paymentHistory_dialog;
    },
    withholding_added() {
      return this.$store.state.withholding_dialog;
    },

    withholding_removed() {
      return this.$store.state.removewithholding_dialog;
    },
    invoice_changed() {
      return this.$store.state.invoice_state_changed;
    },
    posClasses() {
      const lookup = {
        left: "justify-space-between",
        center: null,
        right: "justify-space-between flex-row-reverse",
      };
      return lookup[this.selectedInvoice.logo_position];
    },
    newWidth() {
      const lookup = {
        small: "50px",
        medium: "100px",
        large: "large",
        xlarge: "200px",
      };
      return lookup[this.logoSize];
    },
    centerClass() {
      if (this.selectedInvoice.logo_position == "center") return "mx-auto";
      return null;
    },
    textAlign() {
      if (this.selectedInvoice.logo_position === "right") return "text-left";
      return null;
    },
  },
  filters: {
    toMoneyCap(d) {
      if (!d) return "0.0000";

      const places = d.toString().split(".");
      const isMore = places.length > 1 && places[1].length >= 4;

      if (isMore) {
        return Number(d)
          .toFixed(4)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      return d;
    },
  },
  watch: {
    invioceID() {
      this.get_invoice();
    },
    send_dialog() {
      this.send_attachments = false;
    },
    invoice_changed(oldstate, newstate) {
      this.get_invoice();
    },
    payment_done(oldstate, newstate) {
      this.get_invoice();
    },
    payment_removed(oldstate, newstate) {
      this.get_invoice();
    },
    withholding_added(oldstate, newstate) {
      this.get_invoice();
    },
    withholding_removed(oldstate, newstate) {
      this.get_invoice();
    },
  },
  async mounted() {
    await this.get_invoice();
    this.logoPos = this.$store.state.selected_invoice.logo_position;
    this.logoSize = this.$store.state.selected_invoice.logo_size;
    const color = this.$store.state.selected_invoice.invoice_color;
    if (!color || color === "transparent") this.color = "#1572E8";
    else this.color = color;
    this.fx = this.currencyList.find(
      (c) => c.cc === this.$store.state.selected_invoice.fx_currency
    );
  },
  methods: {
    sendSMS(invoice) {
      this.smsDialog = true;
    },
    remove_email(index) {
      this.other_mails.splice(index, 1);
    },
    add_email() {
      this.other_mails.push({ address: "" });
    },
    duplicate_invoice() {
      this.duplicating = true;

      const formData = new FormData();
      formData.append("invoice_id", this.$store.state.selected_invoice.id);

      axios
        .post("/api/invoice/duplicate", formData)
        .then((res) => {
          this.duplicating = false;
          this.duplicate_dialog = false;
          this.$router.push({
            path: "/income/invoices/" + res.data.id,
          });
          this.success_msg = this.$t("invoice.duplicate_success");
          this.success_message = true;
          this.get_invoice();
        })
        .catch((error) => {
          this.duplicating = false;
        });
    },
    handleAttachmentRemoved(attachment, index) {
      this.isAttachmentRemoveLoading = true;
      axios
        .delete(`/api/invoice/attachment/${attachment.id}`)
        .then(() => {
          this.isAttachmentRemoveLoading = false;
          this.attachments = this.attachments.filter((_, i) => i !== index);
        })
        .catch((err) => {
          this.isAttachmentRemoveLoading = false;
        });
    },

    duplicate() {
      this.duplicate_dialog = true;
    },
    remove_withholding(item) {
      this.$store.state.selected_invoice = item;
      this.$store.state.removewithholding_dialog = true;
    },
    canShowInvoiceItem(num) {
      return Boolean(num);
    },
    add_withholding(item) {
      if (item.withholding.length > 0) {
        this.success_msg =
          "This invoice already has an applied withholding of " +
          item.withholding[0].amount;
        this.success_message = true;
      } else {
        this.$store.state.withholding_dialog = true;
      }
    },
    view_payment(invoice) {
      this.$store.state.selected_invoice = invoice;
      this.paymentHistory_dialog = true;
    },
    change_terms(invoice) {
      this.invoice_id = invoice.id;
      this.startdate = invoice.start_date;
      this.enddate = invoice.end_date;
      this.invoice_number = invoice.invoice_number;
      this.customer_id = invoice.customer.id;
      this.terms_dialog = true;
    },
    delete_invoice(invoice) {
      this.invoice_id = invoice.id;
      this.invoice_number = invoice.invoice_number;
      this.customer_name = invoice.customer.business_name;
      this.delete_dialog = true;
    },

    remove_invoice() {
      this.saving_progress = true;
      axios
        .delete("/api/deleteinvoice/" + this.invoice_id)
        .then((res) => {
          this.saving_progress = false;
          this.delete_dialog = false;
          this.success_msg =
            "Invoice with number " +
            this.invoice_number +
            " billed to " +
            this.customer_name +
            " deleted Permanently";
          this.success_message = true;
          this.$router.push({ path: "/income/invoices" });
        })
        .catch((error) => {
          // if (error.response.status == 302) {
          //   this.error_msg = error.response.data;
          // } else {
          //   this.error_msg =
          //     "Something went wrong, please check your connection";
          // }
          this.saving_progress = false;
          // this.error_message = true;
        });
    },
    add_payment(invoice) {
      //this.$store.state.selected_invoice =JSON.parse(JSON.stringify(invoice));

      if (invoice.paid_status === "Paid") {
        this.error_msg = "This invoice was already marked as Paid";
        this.error_message = true;
        return false;
      }
      this.$store.state.amount_due =
        this.$store.state.selected_invoice.amount_due;

      this.$store.state.payment_dialog = true;
    },

    send_email() {
      if (!this.customer_email && !this.other_mails.length) {
        this.error_msg = "No emails, add recipient emails";
        this.error_message = true;
        return false;
      }

      if (this.$refs.mails_form.validate()) {
        this.saving_progress = true;
        let formdata = {
          invoice_id: this.invoice_id,
          send_tome: this.send_tome ? 1 : 0,
          other_emails: this.compact_other_emails.toString(),
          message: this.email_message,
          send_attachments: this.send_attachments,
        };

        axios
          .post("/api/sendinvoice", formdata)
          .then((res) => {
            this.success_msg =
              this.$t("invoice.with_number") +
              this.invoice_number +
              this.$t("quote.was_sent") +
              this.customer_name +
              this.$t("quote.others");
            this.success_message = true;
            this.send_dialog = false;
            this.saving_progress = false;
          })
          .catch(() => {
            // this.error_msg = this.$t("main.mail_send_err");
            // this.error_message = true;
          });
      }
    },
    send(invoice) {
      this.invoice = invoice;
      this.send_dialog = true;
      this.customer_name = invoice.customer.business_name;
      this.customer_email = invoice.customer.business_email;
      this.invoice_number = invoice.invoice_number;
      this.invoice_id = invoice.id;
    },

    print_invoice(invoice) {
      let w = window.open(
        this.$store.state.baseURL + "/" + "printinvoice/" + invoice.enc_id,
        invoice.invoice_number,
        "height=800,width=900"
      );
      w.onload = function (e) {
        w.print();
      };
    },
    get_invoice() {
      this.progress = true;
      return axios
        .get("/api/invoice/" + this.$route.params.id)
        .then((res) => {
          this.$store.state.selected_invoice = res.data;

          if (this.$route.query.pay && this.$route.query.pay == "true") {
            this.$store.state.payment_dialog = true;
          }

          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          // this.error_msg =
          //   error.response.status === 302
          //     ? error.response.data
          //     : "Something went wrong, could not retrieve invoice ";
          // this.error_message = true;
        });
    },
    is_inArray(tax) {
      let res = false;
      this.taxes.forEach((ta, index) => {
        if (tax.id == ta.id) {
          res = true;
        }
      });

      return res;
    },

    compute_taxes(items, maintax) {
      let total_taxAmount = 0;
      items.forEach((item, index) => {
        if (item.tax_id) {
          if (maintax.type == "Compound" && maintax.id == item.tax_id) {
            //compute input taxes before applying compound taxes
            let subamount = this.compute_inputTax(
              maintax.sub_rate,
              item.amount
            );

            maintax.sub_tax.forEach((subtax) => {
              if (!subtax.amount) {
                subtax.amount = 0;
              }

              subtax.amount += this.compute_inputTax(subtax.rate, item.amount);
            });

            let compound_amount = Number(subamount) + Number(item.amount);
            let compound_taxAmount = this.compute_inputTax(
              maintax.rate,
              compound_amount
            );
            total_taxAmount += compound_taxAmount;
          } else if (maintax.id == item.tax_id) {
            total_taxAmount += this.compute_inputTax(maintax.rate, item.amount);
          }
        }
      });

      return total_taxAmount;
    },

    get_tax_byID(tax_id, taxes) {
      return taxes.filter((tax) => {
        return tax.id == tax_id;
      });
    },

    compute_subtax(items, subtax) {
      let total_taxAmount = 0;
      items.forEach((item, index) => {
        if (item.tax_id) {
        }
      });

      return total_taxAmount;
    },
    compute_inputTax(rate, amount) {
      return (Number(rate) / 100) * Number(amount);
    },
  },
};
</script>

<style scoped>
.minor-text {
  opacity: 0.7;
}

.stretch-out {
  margin: 0 -12px;
  margin-bottom: 2rem;
}
</style>
